import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';

import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import CaptionsPreview from '../Parts/ExampleCaption/CaptionsPreview';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { InfoText } from '@autocut/components/atoms/InfoText';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { ECaptionsOnboardingTargets } from '@autocut/context/OnboardingProvider/OnboardingSteps/CaptionsOnboardingSteps';
import { processCaptionsChunksState } from '@autocut/utils/captions/processCaptionsChunksState.utils';

export const CaptionsPreviewModal = () => {
  const { fontSize, maxWidth, nbLines, chunks } = useAutoCutStore(state => ({
    fontSize: state.ui.parameters.caption.text.fontSize,
    nbLines: state.ui.parameters.caption.formating.nbLines,
    maxWidth: state.ui.parameters.caption.formating.maxWidth,
    chunks: state.onGoingProcess.captionChunks,
  }));

  const parseFontSize = (value: string) => {
    const r = parseInt(value);
    return isNaN(r) ? 0 : r;
  };

  const closeModal = () => {
    processCaptionsChunksState(
      chunks?.flatMap(chunk => chunk.lines.flat()) ?? []
    );
    setAutocutStore('ui.openedModalName', '');
  };

  return (
    <Modal
      data-tour={ECaptionsOnboardingTargets.CAPTIONS_PREVIEW_MODAL}
      title={
        <TranslatedMessage
          id="captions_customization_position_size"
          defaultMessage="Position & size"
        />
      }
      closeModalFunction={closeModal}
      withCloseButton={false}
      closeOnClickOutside={false}
      icon={<MdOutlineModeEditOutline size="1.5rem" />}
      fullScreen
      shouldScroll={false}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={4}
          style={{
            marginTop: -8,
          }}
        >
          <CaptionsPreview maxHeight="240px" />

          <FlexContainer
            flexDirection="row"
            gap={12}
            style={{
              paddingInline: 16,
            }}
          >
            <Button onClick={closeModal} variant="secondary">
              <TranslatedMessage
                id="captions_customization_presets_apply"
                defaultMessage="Apply style"
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      }
    >
      <FlexContainer flexDirection="column" gap={6}>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="captions_customization_font_size"
              defaultMessage="Font size"
            />
          }
          grow
          wrap={true}
        >
          <InputRangeParameter
            value={fontSize}
            step={1}
            min={10}
            max={400}
            unit="px"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.text.fontSize',
                parseFontSize(value)
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="captions_customization_formating_nbLines"
              defaultMessage="Number of lines"
            />
          }
          wrap={true}
        >
          <InputRangeParameter
            value={nbLines}
            min={1}
            max={5}
            step={1}
            unit="lines"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.formating.nbLines',
                parseInt(value)
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="captions_customization_formating_maxWidth"
              defaultMessage="Maximum width"
            />
          }
          wrap={true}
        >
          <InputRangeParameter
            value={maxWidth}
            min={20}
            max={100}
            step={1}
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.formating.maxWidth',
                parseInt(value)
              )
            }
          />
        </ParameterLayout>

        <InfoText style="warning">
          <TranslatedMessage
            id="captions_editor_style_step_subtitle"
            defaultMessage="The preview is not final and may not be accurate."
          />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
