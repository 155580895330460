import React, { useEffect, useMemo } from 'react';

import ContactUsModal from '@autocut/components/modals/ContactUsModal/ContactUsModal';
import ErrorModal from '@autocut/components/modals/ErrorModal/ErrorModal';
import ExchangeRateModal from '@autocut/components/modals/ExchangeRateModal/ExchangeRateModal';
import FeatureRequestModal from '@autocut/components/modals/FeatureRequestModal/FeatureRequestModal';
import { FeedbackModal } from '@autocut/components/modals/FeedbackModal/FeedbackModal';
import HandshakeRestartModal from '@autocut/components/modals/HandshakeRestartModal/HandshakeRestartModal';
import { LostCepModal } from '@autocut/components/modals/LostCepModal/LostCepModal';
import { ReportIssueModal } from '@autocut/components/modals/ReportIssueModal/ReportIssueModal';
import RestartAutoCutModal from '@autocut/components/modals/RestartAutoCutModal/RestartAutoCutModal';
import ReviewModal from '@autocut/components/modals/ReviewModal/ReviewModal';
import ToEnglishModal from '@autocut/components/modals/ToEnglishModal/ToEnglishModal';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StartUpModals } from '@autocut/pages';
import { BRollCreditsModal } from './BRollCreditsModal/BRollCreditsModal';
import { BackupModal } from './BackupModal/BackupModal';
import { CreateResizeFormatModal } from './CreateResizeFormatModal/CreateResizeFormatModal';
import { DeepgramErrorModal } from './DeepgramErrorModal/DeepgramErrorModal';
import { BREAKING_VERSION } from '@autocut/utils/init/checkPproVersion';
import { EndCaptionsProcessModal } from './EndCaptionsProcessModal/EndCaptionsProcessModal';
import { EndProcessModal } from './EndProcessModal/EndProcessModal';
import { GamingTrialModal } from './GamingTrialModal/GamingTrialModal';
import { KeyExpiredModal } from './KeyExpiredModal/KeyExpiredModal';
import { ProcessModal } from './ProcessModal/ProcessModal';
import { UnsupportedRenderingEngineModal } from './UnsupportedRenderingEngineModal.tsx/UnsupportedRenderingEngineModal';
import { UnsupportedVersionModal } from './UnsupportedVersionModal/UnsupportedVersionModal';
import { UsageModal } from './UsageModal/UsageModal';
import { TrialExpiredModal } from './TrialExpiredModal/TrialExpiredModal';
import { setAutocutStore } from '@autocut/utils/zustand';
import { FormattedNumberModal } from './FormattedNumberModal/FormattedNumberModal';
import { CaptionsCustomizationModal } from '@autocut/modes/captions/Steps/Customization/CaptionsCustomizationModal';
import { CreatePresetModal } from '@autocut/modes/captions/Steps/Customization/Parts/PresetCustomization/components/CreatePresetModal/CreatePresetModal';
import { SearchPublicPresetModal } from '@autocut/modes/captions/Steps/Customization/Parts/PresetCustomization/components/SearchPublicPresetModal/SearchPublicPresetModal';
import { DeletePresetsModal } from '@autocut/modes/captions/Steps/Customization/Parts/PresetCustomization/components/ManagePresetsModal/ManagePresetsModal';
import { EditTranscriptModal } from '@autocut/modes/captions/Steps/Customization/Parts/PresetCustomization/components/EditTranscriptModal/EditTranscriptModal';
import { GenerateEmojisModal } from '@autocut/modes/captions/Steps/Transcript/CaptionsTranscriptEditorStep/CaptionsTranscriptButtons/GenerateEmojisModal/GenerateEmojisModal';
import { WindowTooSmallModal } from './WindowTooSmallModal/WindowTooSmallModal';
import { CaptionsPreviewModal } from '@autocut/modes/captions/Steps/Customization/CaptionsPreviewModal/CaptionsPreviewModal';
import { OnboardingWelcomeModal } from './OnboardingModals/OnboardingWelcomeModal/OnboardingWelcomeModal';
import { OnboardingCaptionsModal } from './OnboardingModals/OnboardingCaptionsModal/OnboardingCaptionsModal';
import UsageStatsModal from '@autocut/components/modals/UsageStatsModal/UsageStatsModal';

const ModalManager = () => {
  const { openedModalName, modalQueue, isTourOpened, pproVersion } =
    useAutoCutStore(state => ({
      openedModalName: state.ui.openedModalName,
      modalQueue: state.ui.modalQueue,
      isTourOpened: state.ui.isTourOpened,
      pproVersion: state.ppro.version,
    }));

  useEffect(() => {
    if (!openedModalName && modalQueue.length > 0) {
      const newModal = modalQueue.splice(0, 1);
      setAutocutStore('ui.openedModalName', newModal[0]);
      setAutocutStore('ui.modalQueue', modalQueue);
    }
  }, [openedModalName, modalQueue]);

  const ModalComponent = useMemo(() => {
    if (BREAKING_VERSION.includes(pproVersion)) return UnsupportedVersionModal;

    switch (openedModalName) {
      // Global modals
      case ModalType.ToEnglish:
        return ToEnglishModal;
      case ModalType.LostCepConnection:
        return LostCepModal;
      case ModalType.Feedback:
        return FeedbackModal;
      case ModalType.TrialExpired:
        return TrialExpiredModal;
      case ModalType.StartUp:
        return StartUpModals;
      case ModalType.Backup:
        return BackupModal;
      case ModalType.Processing:
        return ProcessModal;
      case ModalType.Onboarding:
        return OnboardingWelcomeModal;
      case ModalType.KeyExpired:
        return KeyExpiredModal;
      case ModalType.GamingTrial:
        return GamingTrialModal;
      case ModalType.UnsupportedVersion:
        return UnsupportedVersionModal;
      case ModalType.UnsupportedRenderingEngine:
        return UnsupportedRenderingEngineModal;
      case ModalType.FormattedNumber:
        return FormattedNumberModal;
      case ModalType.HandshakeRestart:
        return HandshakeRestartModal;
      case ModalType.UsageStats:
        return UsageStatsModal;
      case ModalType.WindowTooSmall:
        return WindowTooSmallModal;

      // Header modals
      case ModalType.ContactUs:
        return ContactUsModal;
      case ModalType.FeatureRequest:
        return FeatureRequestModal;
      case ModalType.ReportIssue:
        return ReportIssueModal;
      case ModalType.RestartAutoCut:
        return RestartAutoCutModal;

      // Process modals
      case ModalType.Review:
        return ReviewModal;
      case ModalType.ExchangeRate:
        return ExchangeRateModal;
      case ModalType.EndProcess:
        return EndProcessModal;
      case ModalType.EndCaptionsProcess:
        return EndCaptionsProcessModal;
      case ModalType.BRollCredits:
        return BRollCreditsModal;
      case ModalType.CaptionsCustomization:
        return CaptionsCustomizationModal;
      case ModalType.CaptionsPreview:
        return CaptionsPreviewModal;

      // Modes modals
      case ModalType.CreateResizeFormat:
        return CreateResizeFormatModal;
      case ModalType.CreatePresetModal:
        return CreatePresetModal;
      case ModalType.SearchPreset:
        return SearchPublicPresetModal;
      case ModalType.DeletePresets:
        return DeletePresetsModal;
      case ModalType.EditTranscript:
        return EditTranscriptModal;
      case ModalType.GenerateEmojis:
        return GenerateEmojisModal;

      // Errors modals
      case ModalType.Error:
        return ErrorModal;
      case ModalType.UsageLimit:
        return UsageModal;
      case ModalType.DeepgramError:
        return DeepgramErrorModal;

      // Onboarding modals
      case ModalType.OnboardingWelcome:
        return OnboardingWelcomeModal;
      case ModalType.OnboardingCaptions:
        return OnboardingCaptionsModal;

      default:
        return null;
    }
  }, [openedModalName]);

  return ModalComponent && !isTourOpened ? <ModalComponent /> : null;
};

export default ModalManager;
